import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import Metadata from "../Metadata";
import styles from "./index.module.css"

const Post = ({ title, date, slug, description, image, timeToRead }) => (
  <div className={styles.root} key={slug}>
    <div>
    {image && <Image fluid={image.childImageSharp.fluid} className={styles.image} />}
    </div>
    <div>
      <h2>
        <Link className={styles.link} to={'/post' + slug}>
          {title}
        </Link>
      </h2>
      <p
        dangerouslySetInnerHTML={{
          __html: description,
        }}
        />
        <Metadata timeToRead={timeToRead} publishedDate={date} inContent />
    </div>
  </div>
)

export default Post
