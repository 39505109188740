import React from "react"
import cn from "classnames"
import styles from "./index.module.css"
import { formatDate } from "../../utils/date"

const timeDeclination = (t) => {
  if (t === 1)
    return "minuta"
  if (t >= 5)
    return "minut"
  return "minuty"
}

const Metadata = ({ timeToRead, publishedDate, inContent = false }) => (
  <div className={cn(styles.root, { [styles.static]: inContent })}>
    <div>Opublikowano {formatDate(publishedDate)}</div>
    <div>{timeToRead} {timeDeclination(timeToRead)}</div>
  </div>
)

export default Metadata
