import React from "react"
import { graphql } from "gatsby"
import styles from "./index.module.css"

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import Post from "../components/Post"
import SEO from "../components/SEO"

class TagList extends React.PureComponent {
  render() {
    const { data, pageContext } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title="PR0GRAMISTA | Posty">
        <SEO
          title={"#" + pageContext.tag}
          keywords={[pageContext.tag, `tag`, 'programowanie']}
        />
        <Bio hiding />
        <h1>#{pageContext.tag}</h1>
        <div className={styles.postList}>
          {posts.map(({ node }) => (
            <Post
              key={node.fields.slug}
              title={node.frontmatter.title || node.fields.slug}
              slug={node.fields.slug}
              description={node.frontmatter.description || node.excerpt}
              date={node.frontmatter.date}
              image={node.frontmatter.image}
              timeToRead={node.timeToRead}
            />
          ))}
        </div>
      </Layout>
    )
  }
}

export default TagList

export const pageQuery = graphql`
  query tagListQuery($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
